import { Button } from "@/components/Button";
import Layout from "@/components/Layout";
import { FEATURES_STORE_KEY } from "@/utils/localStorage";
import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";

export default function FeatureTogglesPage() {
  const features = useFeaturesStore((state) => state.features);
  const toggleFeature = useFeaturesStore((state) => state.toggleFeature);

  const resetFeatures = () => {
    localStorage.removeItem(FEATURES_STORE_KEY);
    window.location.reload();
  };

  return (
    <Layout title="Features" back={{ to: -1, label: "Back" }}>
      <p className="text-sm">
        Don't see the feature flag you are looking for? Click the button below
        to reset the cache. Caution: it will reload the page
      </p>
      <div className="mt-4">
        <Button onClick={resetFeatures} color="default">
          Reset features
        </Button>
      </div>
      <ul className="mt-4">
        {Object.keys(features).map((feature) => (
          <li key={feature}>
            <label htmlFor={feature} className="flex items-center space-x-2">
              <input
                id={feature}
                type="checkbox"
                checked={features[feature]}
                onChange={() => toggleFeature(feature)}
              />
              <span className="capitalize">{feature}</span>
            </label>
          </li>
        ))}
      </ul>
    </Layout>
  );
}

// create a function thats returns the feature toggle only from available features and throw an error if the feature is not available
export function useFeatureToggle(feature: string) {
  const features = useFeaturesStore((state) => state.features);

  if (!features[feature]) {
    return false;
  }

  return features[feature];
}

interface FeaturesState {
  features: { [key: string]: boolean };
  toggleFeature: (feature: string) => void;
}

const useFeaturesStore = create<FeaturesState>()(
  devtools(
    persist(
      (set) => ({
        features: {
          guests: true,
          commFeed: false
          // add more features here
        },
        toggleFeature: (feature: string) =>
          set((state) => ({
            features: {
              ...state.features,
              [feature]: !state.features[feature]
            }
          }))
      }),
      { name: FEATURES_STORE_KEY }
    )
  )
);
