import moment, { Moment } from "moment-timezone";
import { forwardRef } from "react";
import Alert from "@/components/Alert";
import DatePicker from "react-datepicker";
import { textInputClass } from "@/components/Form";
import { addDays } from "date-fns";

type Props = {
  day: number | null;
  isAutoPayActive: boolean;
  startDate: string | Date | null;
  endDate: string | Date | null;
  isStartError: boolean;
  isEndError: boolean;
  isStartAfterEndError: boolean;
  setStartDate: (startDate: Moment | null) => void;
  setEndDate: (endDate: Moment | null) => void;

  agreedToEndDateOverride: boolean;
  setAgreedToEndDateOverride: (agreed: boolean) => void;
};

const PaymentStartStop = ({
  isAutoPayActive,
  startDate,
  endDate,
  day,
  isStartError,
  isEndError,
  isStartAfterEndError,
  setStartDate,
  setEndDate,
  agreedToEndDateOverride,
  setAgreedToEndDateOverride,
}: Props) => {
  const ExampleCustomInput = forwardRef(({ value, onClick }: any, ref) => (
    <input
      className={textInputClass}
      onClick={onClick}
      //@ts-ignore
      ref={ref}
      value={value}
    />
  ));

  return (
    <div id="autopay-start-stop" className="pt-8 auto-pay-dates">
      <div className="flex justify-between gap-8 md:justify-start">
        <div>
          <p>Start date</p>
          <div className="border-b border-gray-200">
            <DatePicker
              minDate={addDays(new Date(), 1)}
              selected={startDate ? new Date(startDate) : null}
              disabled={isAutoPayActive}
              onChange={setStartDate}
              customInput={<ExampleCustomInput />}
            />
            {/* <DatePicker
              id="start-date"
              disabled={isAutoPayActive}
              date={startDate ? moment(startDate) : null}
              setDate={setStartDate}
            /> */}
          </div>
        </div>
        <div>
          <p>End date</p>
          <div className="border-b border-gray-200">
            <DatePicker
              minDate={addDays(new Date(), 1)}
              selected={endDate ? new Date(endDate) : null}
              disabled={isAutoPayActive}
              onChange={setEndDate}
              customInput={<ExampleCustomInput />}
            />
            {/*  <DatePicker
              id="end-date"
              date={endDate ? moment(endDate) : null}
              setDate={setEndDate}
            /> */}
          </div>
        </div>
      </div>
      <div className="mt-4">
        {isStartError && (
          <Alert variant="danger" message="Start date must be after today." />
        )}
        {isStartAfterEndError && (
          <Alert
            variant="danger"
            message="Start date cannot be after end date."
          />
        )}
        {isEndError && (
          <div className="mt-6">
            <label className="flex items-start gap-4">
              <input
                className="mt-2"
                type="checkbox"
                checked={agreedToEndDateOverride}
                onChange={(e) => setAgreedToEndDateOverride(e.target.checked)}
              />
              <p>
                The date selected is within 30 days or past your lease end date.
                Autopayments will be processed on the{" "}
                {moment()
                  .month(0)
                  .date(day ?? 1)
                  .format("Do")}{" "}
                of the month until {moment(endDate).format("M/D/YYYY")}. Select
                the checkbox to confirm payments can be processed every month
                through {moment(endDate).format("M/D/YYYY")}
              </p>
            </label>
          </div>
        )}
      </div>
    </div>
  );
};
/* 
function DatePicker({
  disabled = false,
  id,
  date,
  setDate,
}: {
  disabled?: boolean;
  id: string;
  date: Moment | null;
  setDate: (date: Moment | null) => void;
}) {
  const [focused, setFocused] = useState(false);

  return (
    <SingleDatePicker
      disabled={disabled}
      readOnly
      noBorder
      placeholder="mm/dd/yyyy"
      numberOfMonths={1}
      id={id}
      date={date}
      onDateChange={setDate}
      focused={focused}
      isOutsideRange={(day) => isInclusivelyBeforeDay(day, moment())}
      onFocusChange={({ focused }: { focused: boolean }) => setFocused(focused)}
    />
  );
}
 */
export { PaymentStartStop };
