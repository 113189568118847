import { BaseLivlyApiResponse } from "@/types/Base";
import {
  CommFeedImageUploadResponseModel,
  CommunityFeedPostRequest,
  CommunityFeedPostRequestModel,
  FeedPost,
  FeedPostComment,
  ReactionType,
  TagType
} from "@/utils/commfeed";
import { BASE_API_URL } from "@/utils/constants";
import axios from "axios";
import { ImageUpload } from "react-file-utils";
import validator from "validator";

export const getAllCommFeeds = async (
  leaseId: number,
  tag: TagType | undefined,
  limit: number,
  offset: number
) => {
  const baseURL = `${BASE_API_URL}/livly/communityFeed/lease/${leaseId}/building`;
  let params = new URLSearchParams();
  params.append("limit", limit.toString());
  params.append("offset", offset.toString());

  if (tag !== undefined && !isNaN(tag)) {
    params.append("tag", String(tag));
  }

  const { data } = await axios.get<BaseLivlyApiResponse<FeedPost[]>>(
    `${baseURL}?${params}`
  );

  return {
    data: data.Data,
    nextPage: data.Data.length === 20 ? offset + 20 : undefined
  };
};

export const getMyCommFeeds = async (
  leaseId: number,
  limit: number,
  offset: number
) => {
  const baseURL = `${BASE_API_URL}/livly/communityFeed/lease/${leaseId}`;

  let params = new URLSearchParams();
  params.append("limit", limit.toString());
  params.append("offset", offset.toString());

  const { data } = await axios.get<BaseLivlyApiResponse<FeedPost[]>>(
    `${baseURL}?${params}`
  );

  return {
    data: data.Data,
    nextPage: data.Data.length === 20 ? offset + 20 : undefined
  };
};

export const deleteCommFeedById = async (leaseId: number, postId: number) => {
  const baseURL = `${BASE_API_URL}/livly/communityFeed/lease/${leaseId}/${postId}`;
  const { data } = await axios.delete<BaseLivlyApiResponse<boolean>>(
    `${baseURL}`
  );
  return data.Data;
};

export const hideCommFeed = async (leaseId: number, postId: number) => {
  const baseURL = `${BASE_API_URL}/livly/communityFeed/lease/${leaseId}/${postId}/hide`;
  const { data } = await axios.put<BaseLivlyApiResponse<boolean>>(`${baseURL}`);
  return data.Data;
};

export const reportCommFeed = async (leaseId: number, postId: number) => {
  const baseURL = `${BASE_API_URL}/livly/communityFeed/lease/${leaseId}/${postId}/report`;
  const { data } = await axios.put<BaseLivlyApiResponse<boolean>>(`${baseURL}`);
  return data.Data;
};

export const putFeedPostReaction = async (
  leaseId: string,
  postId: string,
  type: ReactionType | null
) => {
  if (type) {
    const result = await axios.put<BaseLivlyApiResponse<boolean>>(
      `${BASE_API_URL}/livly/communityFeed/lease/${leaseId}/${postId}/reaction?type=${type}`
    );

    return result.data.Data;
  } else {
    const result = await axios.delete(
      `${BASE_API_URL}/livly/communityFeed/lease/${leaseId}/${postId}/reaction`
    );

    return result.data.Data;
  }
};

export const addFeedPostComment = async (
  leaseId: string,
  postId: string,
  comment: string
) => {
  const result = await axios.post<BaseLivlyApiResponse<FeedPost>>(
    `${BASE_API_URL}/livly/communityFeed/lease/${leaseId}/${postId}/comment`,
    { text: comment }
  );

  return result.data.Data;
};

export const editFeedPostComment = async (
  leaseId: string,
  postId: string,
  commentId: string,
  comment: string
) => {
  const result = await axios.patch<BaseLivlyApiResponse<FeedPostComment>>(
    `${BASE_API_URL}/livly/communityFeed/lease/${leaseId}/${postId}/comment/${commentId}`,
    { text: comment }
  );

  return result.data.Data;
};

export const deleteFeedPostComment = async (
  leaseId: string,
  postId: string,
  commentId: string
) => {
  const result = await axios.delete(
    `${BASE_API_URL}/livly/communityFeed/lease/${leaseId}/${postId}/comment/${commentId}`
  );

  return result.data;
};

export const sharePost = async (
  leaseId: number,
  post: CommunityFeedPostRequest
): Promise<BaseLivlyApiResponse<FeedPost>> => {
  let response = await axios.post(
    `${BASE_API_URL}/livly/communityFeed/lease/${leaseId}/`,
    post
  );
  return response.data;
};

export const TransformPostModelToRequest = (
  model: CommunityFeedPostRequestModel
): CommunityFeedPostRequest => {
  return {
    Text: model.text,
    Images: [],
    LivlyEventId: model.eventId,
    DisableComments: false,
    Tag: model.tag,
    ExternalId: model.externalId
  };
};

export const uploadCommFeedImageFile = async (
  leaseId: number,
  correlationId: string,
  file: File
) => {
  const formData = new FormData();
  formData.append("file", file, file.name);

  const result = await axios.post<
    BaseLivlyApiResponse<CommFeedImageUploadResponseModel>
  >(
    `${BASE_API_URL}/livly/communityFeed/lease/${leaseId}/image/${correlationId}`,
    formData
  );

  return result.data.Data;
};

export const editFeedPostAndImages = async (
  leaseId: number,
  post: FeedPost,
  images: ImageUpload[],
  text: string,
  tag: TagType
) => {
  //list of already uploaded images
  const alreadyUploadedImages = images
    .filter(
      (image) =>
        typeof image.previewUri === "string" &&
        validator.isURL(image.previewUri)
    )
    .map((i) => i.previewUri as string);

  //list of images waiting to be uploaded
  const imagesToBeUploaded = images
    .filter(
      (image) =>
        typeof image.previewUri === "string" &&
        validator.isURL(image.previewUri) === false
    )
    .map((img) => img.file as File);

  const uploadedImages = await Promise.all(
    imagesToBeUploaded.map((file) =>
      uploadCommFeedImageFile(leaseId, post.externalId!.toString(), file)
    )
  );

  const postImages = [
    ...alreadyUploadedImages,
    ...uploadedImages.map((i) => i.url)
  ];

  const result = await axios.patch<BaseLivlyApiResponse<FeedPost>>(
    `${BASE_API_URL}/livly/communityFeed/lease/${leaseId}/${post.id}`,
    {
      text,
      tag,
      images: postImages
    }
  );

  return result.data.Data;
};

export const addFeedBuildingPostAndImages = async (
  leaseId: number,
  post: CommunityFeedPostRequestModel
) => {
  try {
    const uploadedImages = await Promise.all(
      post.imageFiles.map((file) =>
        uploadCommFeedImageFile(leaseId, post.externalId, file)
      )
    );

    const transformedPost = TransformPostModelToRequest(post);
    const response = await sharePost(leaseId, {
      ...transformedPost,
      Images: [...post.images, ...uploadedImages.map((i) => i.url)]
    });

    return response.Data;
  } catch (error) {
    return false;
  }
};

export const likeComment = async (
  leaseId: string,
  postId: string,
  commentId: string,
  doLike: boolean
) =>
  await axios.put<BaseLivlyApiResponse<FeedPost>>(
    `${BASE_API_URL}/livly/communityFeed/lease/${leaseId}/${postId}/comment/${commentId}/like?doLike=${doLike}`
  );
