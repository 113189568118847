import { FeedTypes, TagsName, TagType } from "@/utils/commfeed";
import Layout from "../components/Layout";
import AddFeedPostForm from "./community-feed/add-feed-post-form";
import { useState } from "react";
import { motion } from "framer-motion";
import AllCommunityFeed from "./all-community-feed";
import MyCommunityFeed from "./my-community-feed";
import { useFeatureToggle } from "./features";
import desktop from "../assets/download-livly-large.png";
import mobile from "../assets/download-livly-small.png";

export default function CommunityFeedPage() {
  const [statusFilter, setStatusFilter] = useState(FeedTypes.ALL);
  const [selectedTag, setSelectedTag] = useState<TagType | undefined>(
    undefined
  );
  const link = getAppLink();

  const communityFeedFlag = useFeatureToggle("commFeed");

  return (
    <Layout title="Community Feed">
      {communityFeedFlag ? (
        <div className="max-w-2xl mx-auto">
          <AddFeedPostForm />
          <div className="flex items-center justify-between my-4">
            <div className="flex gap-4">
              {[
                ["Recent", FeedTypes.ALL],
                ["Your Posts", FeedTypes.MY]
              ].map(([label, filterState]) => (
                <button
                  key={label}
                  className={`flex-1 flex justify-center md:flex-none relative rounded-lg px-3 py-2 text-gray-700 text-sm font-medium transition-colors delay-150`}
                  onClick={() => setStatusFilter(filterState as FeedTypes)}
                >
                  {filterState === statusFilter && (
                    <motion.span
                      className="absolute inset-0 rounded-lg bg-red-100 "
                      layoutId="hoverBackground"
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1, transition: { duration: 0.15 } }}
                      exit={{
                        opacity: 0,
                        transition: { duration: 0.15, delay: 0.2 }
                      }}
                    />
                  )}
                  <div className="flex gap-1">
                    <span
                      className={`relative z-[1] ${
                        filterState === statusFilter && "text-red-400"
                      }`}
                    >
                      {label}
                    </span>
                  </div>
                </button>
              ))}
            </div>
            {statusFilter === FeedTypes.ALL && (
              <select
                className="border rounded-md py-1 border-gray-300 text-sm"
                value={selectedTag}
                onChange={(e) => {
                  setSelectedTag(
                    e.target.value === "All"
                      ? undefined
                      : Number(e.target.value)
                  );
                }}
              >
                <option value={undefined}>All Posts</option>
                {Array.from(TagsName).map(([key, value]) => (
                  <option key={key} value={key}>
                    {value}
                  </option>
                ))}
              </select>
            )}
          </div>
          <div>
            <div className="mt-4">
              {statusFilter === FeedTypes.ALL ? (
                <AllCommunityFeed selectedTag={selectedTag} />
              ) : (
                <MyCommunityFeed />
              )}
            </div>
          </div>
        </div>
      ) : (
        <a href={link} target="_blank" rel="noopener noreferrer">
          <img
            src={desktop}
            className="hidden w-full md:block"
            alt="download livly"
          />
          <img
            src={mobile}
            className="object-cover w-full md:hidden"
            alt="download livly"
          />
        </a>
      )}
    </Layout>
  );
}

export const getAppLink = () => {
  if (import.meta.env.VITE_ENVIRONMENT === "PRODUCTION") {
    return "https://livly.app.link/vQbPtuiQX4";
  } else {
    return "https://livly.test-app.link/28TYfYIQX4";
  }
};
