import useLivlyUser from "@/context/UserProvider";
import { FeedPost } from "@/utils/commfeed";
import {
  faSignLanguage,
  faSmileBeam,
  faThumbsUp
} from "@fortawesome/free-solid-svg-icons";
import { faHeart } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
export type ReactionType =
  | "Heart"
  | "ClappingHands"
  | "SmileyFace"
  | "ThumbsUp";

export const reactionBgColor: Record<ReactionType, string> = {
  ClappingHands: "bg-yellow-100",
  SmileyFace: "bg-green-100",
  Heart: "bg-red-100",
  ThumbsUp: "bg-blue-100"
};

export const reactionOptions = {
  ClappingHands: (
    <FontAwesomeIcon
      icon={faSignLanguage}
      className="text-yellow-400 text-lg"
    />
  ),
  SmileyFace: (
    <FontAwesomeIcon icon={faSmileBeam} className="text-green-400 text-lg" />
  ),
  Heart: <FontAwesomeIcon icon="heart" className="text-red-400 text-lg" />,
  ThumbsUp: (
    <FontAwesomeIcon icon={faThumbsUp} className="text-blue-400 text-lg" />
  )
};

function PostReactions({
  post,
  onUpdatePostReaction
}: {
  post: FeedPost;
  onUpdatePostReaction: (
    buildingId: string,
    postId: string,
    reactionType: ReactionType | null
  ) => Promise<boolean>;
}) {
  const { user } = useLivlyUser();

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [tempReaction, setTempReaction] = useState(post.reactions);

  useEffect(() => {
    setTempReaction(post.reactions);
  }, [post.reactions]);

  const handleSelectReaction = async (type: ReactionType) => {
    let postId = post.id;
    let leaseId = user.propertyUnitLeaseId;

    if (!postId || !leaseId) {
      return;
    }

    const { userReaction } = tempReaction ?? {
      counts: {},
      totalCount: 0,
      userReaction: null
    };
    setIsMenuOpen(false);

    const newReactionType = type === userReaction ? null : type;
    setTempReaction({
      ...tempReaction,
      userReaction: newReactionType
    });
    setIsLoading(true);
    await onUpdatePostReaction(
      leaseId.toString(),
      postId.toString(),
      newReactionType
    );
    setIsLoading(false);
  };

  return (
    <div className="relative mr-2">
      <button
        onClick={() => setIsMenuOpen(!isMenuOpen)}
        className={`flex items-center px-2 py-1 rounded font-normal text-sm ${
          tempReaction?.userReaction
            ? reactionBgColor[tempReaction.userReaction]
            : ""
        }`}
      >
        {tempReaction?.userReaction ? (
          reactionOptions[tempReaction?.userReaction]
        ) : (
          <>
            React <FontAwesomeIcon icon={faHeart} className="ml-2 text-sm" />
          </>
        )}
        {isLoading && (
          <div className="loader h-3 w-3 ml-2 border-t-2 border-blue-500 rounded-full animate-spin"></div>
        )}
      </button>

      {isMenuOpen && (
        <div
          className="absolute z-10 bg-white shadow-lg rounded-md p-2 flex space-x-4"
          onClick={() => setIsMenuOpen(false)}
        >
          {Object.keys(reactionOptions).map((key) => (
            <button
              key={key}
              onClick={() => handleSelectReaction(key as ReactionType)}
              className="hover:scale-110 transition-transform "
            >
              {reactionOptions[key as ReactionType]}
            </button>
          ))}
        </div>
      )}
    </div>
  );
}

export { PostReactions };
